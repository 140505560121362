import React from "react"

import InformationWrapper from "../InformationWrapper"

import type { MyAccountPageType } from "../../types/MyAccountPageType"

type Props = {|
  sectionStyles?: Object,
  data: MyAccountPageType,
|}

const FitnessProfileSection = ({ sectionStyles = {}, data }: Props) => {
  const {
    fitness_profile_title,
    fitness_profile_description,
    update_fitness_profile_link_text,
  } = data
  return (
    <InformationWrapper
      title={fitness_profile_title}
      updateLink={{
        label: update_fitness_profile_link_text,
        url: process.env.GATSBY_UPDATE_FITNESS_PROFILE_URL,
      }}
      externalLink={process.env.GATSBY_UPDATE_FITNESS_PROFILE_URL}
      classNames={sectionStyles}
    >
      <div className="text-gray-800 mt-24 mb-16">
        {fitness_profile_description}
      </div>
    </InformationWrapper>
  )
}

export default FitnessProfileSection
